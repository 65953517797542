import React from 'react';
import { CalendarEventsList, useCaseCalendarEvents } from '../../Organization/Calendar';

interface Props {
  caseId: string;
}

export const CaseCalendarEvents = (props: Props) => {
  const data = useCaseCalendarEvents(props.caseId);
  return (
    <CalendarEventsList
      data={data}
      schema={data.schema}
      />
  );
}
