import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';


const BackgroundImg = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;

  object-fit: cover;
  object-position: center left;
`;

const BackgroundShade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;

  background: #00000060;

  @keyframes jumpin3 {
    /* 0% { clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); } */
    /* 100% { clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%); } */
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: #00000080;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    animation: jumpin3 0.5s ease-in;
    animation-fill-mode: both;
    /* animation-delay: 0.25s; */
  }
`;

const Tri = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;

  object-fit: cover;
`;

const TriWhite = styled(Tri)`
`;

const TriColor = styled(Tri)`
  filter: drop-shadow(15px 10px 4px #00000020) drop-shadow(-10px 5px 10px #00000020);
`;


const LogoPanel = styled.div`
  position: fixed;
  top: 50px;
  left: 50px;
  width: 200px;
  
  & img {
    width: 100%;
    height: auto;
  }

  & h1 {
    font-size: 32px;
    border-top: 3px solid ${props => props.theme.palette.secondary.main};
    margin-left: 75px;

    margin-top: 10px;
    padding-top: 10px;
  }

  ${props => props.theme.breakpoints.down("md")} {
    left: unset;
    right: 25px;

    & h1 {
      border-color: ${props => props.theme.palette.text.primary};
    }
  }
`;

const PrimaryPanelColumn = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100svh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  & .panel {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    gap: 12px;

    background: #ffffff;
    z-index: 2000;
    width: 400px;
    padding: 24px;
    box-sizing: border-box;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    box-shadow: ${props => props.theme.shadows[6]};

    & h2 {
      font-size: 32px;
      border-bottom: 3px solid ${props => props.theme.palette.secondary.main};
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    padding: 12px;

    & .panel {
      width: 100%;
    }
  }
`;


interface Props {
  
}

export const PublicPageHarness = (props: PropsWithChildren<Props>) => {
  return (
    <>
      <BackgroundImg src="/img/library_3k.jpg" />
      <BackgroundShade />
      <TriWhite src="/img/raca_tri_white.svg" />
      <TriColor src="/img/raca_tri_color.svg" />

      <LogoPanel>
        <img src="/img/logo.svg" alt="RAC" />
        <Typography variant="h1">Архив</Typography>
      </LogoPanel>

      <PrimaryPanelColumn>
        {props.children}
      </PrimaryPanelColumn>
    </>
  );
}
