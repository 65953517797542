import { useState, useEffect } from 'react';
import { Case } from '../../../typings/Cases';
import { PagedLoadedData, useLoadedData, usePagedLoadedData } from '../../../toolympus/hooks/useLoadedData';
import { useHistory, useLocation } from 'react-router-dom';
import { FieldSorting, useFieldSorting } from '../../../toolympus/hooks/useFieldSorting';
import { useQuery } from '../../../toolympus/hooks/useQueryParamsState';
import { useThrottledDoubleState } from '../../../toolympus/components/primitives';

export enum CaseListType {
    Main = 0,
    Hidden = 1,
    Checked = 2,
    Unchecked = 3,
    My = 4,
    MyCurrent = 5,
}

const CaseViewNames = {
    [CaseListType.Main]: 'all',
    [CaseListType.Hidden]: 'hidden',
    [CaseListType.Checked]: 'checked',
    [CaseListType.Unchecked]: 'unchecked', 
    [CaseListType.My]: 'user-cases', 
    [CaseListType.MyCurrent]: 'user-cases-current', 
}

const CaseTypeNames: any = {
    all: CaseListType.Main,
    hidden: CaseListType.Hidden,
    checked: CaseListType.Checked,
    unchecked: CaseListType.Unchecked, 
    "user-cases": CaseListType.My,
    "user-cases-current": CaseListType.MyCurrent,
}

export interface CaseListData extends PagedLoadedData<Case> {
    filter: string;
    setFilter: (v: string) => void;

    listtype: CaseListType;
    setListType: (t: CaseListType) => void;
    sorting: FieldSorting;
}

export const useCaseList = (): CaseListData => {
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    
    const [filter, setFilter, appliedFilter] = useThrottledDoubleState<string>(query.get('filter') || '', 500);
    
    const [listtype, setListType] = useState<CaseListType>(CaseTypeNames[query.get('type') || 'user-cases-current']);
    const sorting = useFieldSorting();

    const filterPart = appliedFilter && appliedFilter.length && `filter=${appliedFilter}`;
    const ltPart = `view=${CaseViewNames[listtype]}`;
    const url = `/api/case?${[filterPart, ltPart, sorting.queryParameter].filter(i => !!i).join('&')}`;
    const data = usePagedLoadedData<Case>(url);

    useEffect(() => {
        query.set("filter", appliedFilter || '');
        query.set("type", CaseViewNames[listtype]);
        history.replace({ pathname: location.pathname, search: `?${query.toString()}`});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilter, listtype, history]);

    return {
        ...data,
        filter,
        setFilter,
        listtype,
        setListType: (v: CaseListType) => setListType(v),
        sorting,
    }
}

export const useEmployeeCaseList = (employeeId: string) =>
    usePagedLoadedData<Case>(`/api/case?view=employee-cases&employee_id=${employeeId}`);

export const useCaseNbrs = (caseId?: string, doLoad?: boolean) => useLoadedData<Pick<Case, '_id' | 'casenbr'>[]>(`/api/case/nbrs?${caseId ? `case_id=${caseId}` : ""}`, [], doLoad);