import BackgroundImageHatchWhite from '../dot-hatch-w.svg';
import BackgroundImageHatchBlack from '../dot-hatch-b.svg';
import Weird1Img from '../weird.png';
import { ReactNode } from 'react';

export interface BackgroundOption {
  name: string;
  label: ReactNode;
  css: string;
}

export const BackgroundBlue = {
  name: "blue",
  label: "Голубой",
  css: `background: #5B99D2 url(${BackgroundImageHatchWhite});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundRed = {
  name: "red",
  label: "Красный",
  css: `background: #AF252A url(${BackgroundImageHatchWhite});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundDarkGray = {
  name: "darkgray",
  label: "Темный",
  css: `background: #222222 url(${BackgroundImageHatchWhite});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundGray = {
  name: "gray",
  label: "Серый",
  css: `background: #f5f5f5 url(${BackgroundImageHatchBlack});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}


export const Weird1 = {
  name: "weird1",
  label: "IFEELWEIRD",
  css: `background: url(${Weird1Img});\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}


export const BackgroundWhite = {
  name: "white",
  label: "Белый",
  css: `background: #ffffff;\nbackground-size: 200px 200px;\nbackground-repeat: repeat;\n`,
}

export const BackgroundOptionDefault = BackgroundBlue;

export const BackgroundOptions = [
  BackgroundBlue,
  BackgroundRed,
  Weird1,
  BackgroundDarkGray,
  BackgroundGray,
  BackgroundWhite,
];
