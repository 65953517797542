import { useMemo, useState } from "react";
import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { FieldFilters, Filter } from "../../../toolympus/components/schemed/Filtering";
import { FieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { useLoadedData } from "../../../toolympus/hooks/useLoadedData";
import { mergeSchema, Schema, useSchema } from "../../../toolympus/hooks/useSchema";
import { Arbitrator } from "../../../typings/Arbitrators";
import { Case } from "../../../typings/Cases";
import { SmartListData, useSmartList } from "../../../toolympus/hooks/useSmartList";
import { useThrottledDoubleState } from "../../../toolympus/components/primitives";
import { Action, useAction } from "../../../toolympus/api/useAction";
import { useSnackbar } from "notistack";

export interface ArbitratorSelection extends Arbitrator {
    question_id?: string | null;
    arbitrator_id: string;
    document_id?: string | null;
    confirmation_status?: string;
    is_approved?: boolean;
    is_excluded?: boolean;
    disclosure_details?: string | null;
    disclosure_document_id?: string | null;
}

export interface ArbitratorSelectionData {
    question_id: string;
    data: ArbitratorSelection[];
    selected: ArbitratorSelection[];
    reloadSelected: () => void;
    isLoading: boolean;
    reload: () => void;
    select: (id: string) => void;
    deselect: (id: string) => void;
    updateArbitrator: (id: string, changes: Partial<ArbitratorSelection>) => void;

    filter: string;
    setFilter: (v: string) => void;
    
    includeDontAssign: boolean;
    setIncludeDontAssign: (v: boolean) => void;
    
    filtering: FieldFilters;
    isSettingFilters: boolean;
    setFiltersFromCase: (caseId: string | undefined | null) => void;
    
    ensureLoaded: () => void;
    sorting: FieldSorting;
    schema: Schema;
    disableEdits: boolean;
    limitTrigger: SmartListData<ArbitratorSelection>["limitTrigger"];
    pickRandom: Action<{}>;
}

const applyFiltersFromCase = (filters: FieldFilters, c: Case) => {
    const filterSettings: [string, Filter["op"], (c: Case) => any, (v: any) => any][] = [
        ["language", "oneof", c => c.arbitration_language, v => ([v])],
        ["country", "oneof", c => c.place_of_arbitration_country, v => ([v])],
        ["city", "equals", c => c.place_of_arbitration_city, v => v],
        [
            "experts_list_site",
            "oneof",
            c => {
                switch(c.rules) {
                    case "nuclear":
                        return "nuclear";
                    case "agriculture":
                        return "agriculture";
                    case "external":
                        return "internationalarb";
                    default:
                        return null;
                }
            },
            v => ([v])],
    ];

    filterSettings.forEach(([arbField, op, getValue, valueToFilterValue]) => {
        const value = getValue(c);
        if(value) {
            const changes: Partial<Filter> = { op, value: valueToFilterValue(value) };
            const existing = filters.filters.find(f => f.field === arbField);
            if(existing) {
                filters.update(existing, changes);
            } else {
                filters.addPopulated(({ field: arbField, ...changes }));
            }
        }
    })
    
}

interface Config {
    disableEdits?: boolean;
}

export const usePresidiumQuestionArbitratorsSelection = (questionId: string, cfg?: Config): ArbitratorSelectionData => {
    const { arbitrator_suggestion: arbitratorSchema } = useSchema();
    const schema = mergeSchema(arbitratorSchema, {
        question_id: { label: " "},
    });

    const [doLoad, setDoLoad] = useState<boolean>(false);
    const [includeDontAssign, setIncludeDontAssign] = useState<boolean>(false);
    const [filter, setFilter, appliedFilter] = useThrottledDoubleState<string>("", 500);
    const snackbar = useSnackbar();
    
    const baseUrl = `/api/presidium/question/${questionId}/arbitrator`;
    
    const data = useSmartList<ArbitratorSelection>(`${baseUrl}/candidate`, {
      newRecordDefault: { },
      schema,
      sorting: { },
      lsKeysPrefix: "_raca_arb_selection",
      noView: true,
      noLoad: !doLoad,
      noCount: true,
      extraParams: {
        name: appliedFilter && appliedFilter.length ? appliedFilter : undefined,
        dont_assign: includeDontAssign ? "include" : undefined,
      },
      fieldFiltersSettingsKey: "filters_arbitrators",
    });
    const [isSettingFilters, setIsSettingFilters] = useState<boolean>(false);

    const setFiltersFromCase = (caseId: string | null | undefined) => {
        if(!caseId) {
            return;
        }

        setIsSettingFilters(true);
        apiFetch<Case>(`/api/case/${caseId}`)
            .then(c => {
                applyFiltersFromCase(data.filtering, c);
                setIsSettingFilters(false);
            })
            .catch(e => {
                setIsSettingFilters(false);
                throw e;
            })
    }

    const selectedData = useLoadedData<ArbitratorSelection[]>(baseUrl, []);

    const refresh = () => {
        data.reload();
        selectedData.reload();
    };

    const select = (arbitrator_id: string) => {
        if(cfg?.disableEdits) {
            return;
        }
        return apiFetch<any>(`${baseUrl}`, FetchTypes.POST, { arbitrator_id })
            .then(refresh);
    }

    const deselect = (arbitrator_id: string) => {
        if(cfg?.disableEdits) {
            return;
        }
        return apiFetch<any>(`${baseUrl}/${arbitrator_id}`, FetchTypes.DELETE)
            .then(refresh);
    }

    const updateArbitrator = (arbitrator_id: string, changes: Partial<ArbitratorSelection>) => {
        if(cfg?.disableEdits) {
            return;
        }
        return apiFetch<any>(`${baseUrl}/${arbitrator_id}`, FetchTypes.PUT, changes)
            .then(refresh);
    }

    const filteredData = useMemo(() => {
      return data.data.filter(a => !selectedData.data.find(x => x._id === a._id)?.is_excluded);
    }, [data.data, selectedData.data]);


    const pickRandom = useAction(async () => {
      const candidateId = await apiFetch<{ _id?: string }>(`${baseUrl}/random-candidate?${data.queryNoLimit}`, "get");
      
      if(candidateId._id) {
        select(candidateId._id);
        snackbar.enqueueSnackbar({ message: "Добавили случайного кандидата по фильтрам", variant: "success", autoHideDuration: 3000 });
      } else {
        snackbar.enqueueSnackbar({ message: "По заданным фильтрам не удалось найти случайного кандидата", variant: "warning", autoHideDuration: 5000 });
      }
      return {};
    });


    return {
        ...data,
        question_id: questionId,
        data: filteredData,
        selected: selectedData.data,
        reloadSelected: selectedData.reload,
        select,
        deselect,
        updateArbitrator,

        schema,
        filter,
        setFilter,
        isSettingFilters,
        setFiltersFromCase,
        ensureLoaded: () => setDoLoad(true),

        includeDontAssign,
        setIncludeDontAssign,
        disableEdits: !!cfg?.disableEdits,
        pickRandom,
    }
} 