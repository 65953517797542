import React from 'react';
import { CalendarEvent } from './useCalendarEvents';
import { useFormats } from '../../../toolympus/components/schemed';
import moment from 'moment';

interface EventDisplayProps {
  event: CalendarEvent;
}

export const EventStartDate = ({ event }: EventDisplayProps) => {
  const formats = useFormats();

  return (
    <>{formats.formatDate(event.start_datetime)}</>
  );
}

export const EventStartTime = ({ event }: EventDisplayProps) => {
  return (
    <>{moment(event.start_datetime).format("HH:mm")}</>
  );
}

export const EventEndTime = ({ event }: EventDisplayProps) => {
  const formats = useFormats();

  const start = moment(event.start_datetime);
  const end = moment(event.end_datetime);

  if(start.isSame(end, "date")) {
    return <>{end.format("HH:mm")}</>
  } else {
    return <>{end.format("HH:mm")} ! {formats.formatDate(event.end_datetime)}</>
  }
}
