import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { TableForFields, FormControl } from '../../../toolympus/components/schemed';
import { FieldType, useSchema } from '../../../toolympus/hooks/useSchema';
import { ArbitratorListData } from '../arbitratorsData';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { Form } from '../../../toolympus/components/primitives/Forms';
import { useFields } from '../../../toolympus/components/schemed';
import { FieldSettingsPopupButton } from '../../../toolympus/components/schemed';
import { useTableComments } from '../../Comments';
import { FieldFiltersPopupButton } from '../../../toolympus/components/schemed/Filtering';
import { ActionRow, Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { SearchField } from '../../../toolympus/components/primitives/SearchField';
import { NewArbitratorModal } from './NewArbitratorModal';
import { InsetPanel } from '../../Common/UI';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';
import { useHistory } from 'react-router-dom';

interface Props {
    arbitrators: ArbitratorListData;
}

const ArbitratorList = ({ arbitrators }: Props) => {
    const [isNewFormActive, setIsNewFormActive] = useState(false);

    const { arbitrator: schema } = useSchema();

    const history = useHistory();

    const fields = useFields({
        defaultFields: [
            "lastname",
            "firstname",
            "middlename",
            "country",
            "city",
            "experts_list_site",
            "info",
            "phone",
            "email",
        ],
        schema,
        storageKey: "fields_arbitrators",
    });

    const comments = useTableComments('arbitrator');

    const fieldLink = (field: string) =>
        (field === 'firstname' || field === 'lastname' || field === 'middlename' ? ({_id}: { _id: string}) => `/arbitrator/${_id}` : null);

    return (
        <Form title="Арбитры"
            headerItems={<ActionRow itemMarginTop="0" alignItems="flex-end">
                <IconButton color='primary' onClick={() => setIsNewFormActive(true)}><Add /></IconButton>
                {arbitrators.isLoading && <LoadingIndicator sizeVariant="m" />}
                
                <OccupyFreeSpace />


                
                <SearchField
                  autoFocus
                  filter={arbitrators.filter}
                  setFilter={v => arbitrators.setFilter(v)}
                  noButton
                  doSearch={() => {
                    if(arbitrators.data.length === 1) {
                      history.push(`/arbitrator/${arbitrators.data[0]._id}`)
                    }
                  }}
                  />
                
                <Buttons gap="small">
                  <FieldFiltersPopupButton fieldFilters={arbitrators.filtering} />
                  <FieldSettingsPopupButton fieldsSettings={fields} />
                  <PluggableDocumentationButton documentationKey="arbitrators" />
                </Buttons>
            </ActionRow>}>

            <NewArbitratorModal
                isActive={isNewFormActive}
                setIsActive={setIsNewFormActive}
                data={arbitrators.newArbitrator}
                />

            <InsetPanel>

              <Buttons>
                <FormControl
                    field="v"
                    schema={{ label: 'Включая арбитров вне баз', type: FieldType.bool }}
                    row={{ v: arbitrators.includeNoList }}
                    onChange={(_, changes) => arbitrators.setIncludeNoList(changes.v)}
                    extraProps={{ controlProps: { size: "small"}}}
                    />

                <FormControl
                    field="v"
                    schema={{ label: 'Включая "Не назначать"', type: FieldType.bool }}
                    row={{ v: arbitrators.includeDontAssign }}
                    onChange={(_, changes) => arbitrators.setIncludeDontAssign(changes.v)}
                    extraProps={{ controlProps: { size: "small"}}}
                    />

                <OccupyFreeSpace />
                <Typography>Всего записей: {arbitrators.count.data.total_records}</Typography>
              </Buttons>

            </InsetPanel>
            
            <TableForFields
                fields={fields.activeFields}
                schema={schema}
                data={arbitrators.data}
                sorting={arbitrators.sorting}
                fieldLink={fieldLink}
                rowButtons={row => comments.buttonForRecord(row._id)}
                />
            {arbitrators.limitTrigger.pagingAnchor}
            {comments.popup}
            
        </Form>
    )
};

export default ArbitratorList;