import React from 'react';
import { LoadedData } from '../../../toolympus/hooks/useLoadedData';
import { CalendarEvent, useMyEmployeeId } from './useCalendarEvents';
import { TableForFields } from '../../../toolympus/components/schemed';
import { PseudoLink2 } from '../../../toolympus/components/primitives';
import { OwnershipBadge } from './Elements';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { EventEndTime, EventStartDate, EventStartTime } from './CalendarPrimitives';

interface Props {
  data: LoadedData<CalendarEvent[]>;
  schema: Schema;
}

export const CalendarEventsList = (props: Props) => {
  const myEmployeeId = useMyEmployeeId();

  const { data, schema } = props;

  return (
    <TableForFields
      fields={[
        ["title"],
        ["start_date", { label: "Дата" }],
        ["start_datetime"],
        ["end_datetime"],
        ["confirmation"],
        ["owners"],
      ]}
      data={data.data}
      schema={schema}
      fieldElement={f => {
        switch(f) {
          case "title":
            return (r,s,o) => (
              <PseudoLink2>
                <OwnershipBadge event={r} myEmployeeId={myEmployeeId}>
                  <span>
                    {o}
                  </span>
                </OwnershipBadge>
              </PseudoLink2>
            );
          case "start_date":
            return (r) => <EventStartDate event={r} />;
          case "start_datetime":
            return r => <EventStartTime event={r} />;
          case "end_datetime":
            return r => <EventEndTime event={r} />;
        }
      }}
      />
  );
}
