import { useEditItem2 } from "../../../toolympus/api/useNewItem";
import { useUserSettings } from "../../../toolympus/components/Settings/UserSettingsContext";
import { FieldType, Schema } from "../../../toolympus/hooks/useSchema";

export interface CalendarEventsSettings {
  raca_admin_staff_for_calendar: string[];
}

export const CalendarEventsSchema: Schema = {
  raca_admin_staff_for_calendar: {
    type: FieldType.dictionarySelectMulti,
    dictionary: "Users",
    label: "Офис-менеджеры",
    hint: "Этим пользователям будут отправляться email-уведомления для подготовки переговорок",
  },
}

export const useCalendarEventsSettings = () => {
  const settings = useUserSettings();

  const data = useEditItem2<CalendarEventsSettings>({
    save: (item, changes) => {
      return settings.updateGlobal(changes) as unknown as Promise<CalendarEventsSettings>;
    }
  })

  return {
    ...data,
    startEditing: () => {
      data.startEditing(settings.global as unknown as CalendarEventsSettings);
    },
    schema: CalendarEventsSchema,
  }
}
