import React from 'react';
import styled from '@emotion/styled';
import { ButtonOptionPicker, Form, LoadingIndicator, OccupyFreeSpace, PseudoLink2, SearchField, Tooltip, useWindowHotkey } from '../../../toolympus/components/primitives';
import { Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { calendarMonthFilterFromDate, FutureFilter, PastFilter, useCalendarEvents } from './useCalendarEvents';
import { EditCalendarEventDialog } from './EditCalendarEventDialog';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';
import { TableForFields } from '../../../toolympus/components/schemed';
import { InsetPanel } from '../../Common/UI';
import { ConfRoomsActivatorPicker } from './ConfRoomsActivatorPicker';
import { Add, CalendarMonthOutlined, Settings } from '@mui/icons-material';
import { CalendarEventsView } from './CalendarEventsView';
import { ConflictsBadge, OwnershipBadge } from './Elements';
import { EventStartDate, EventStartTime, EventEndTime } from './CalendarPrimitives';

interface Props {
  
}

const ConfRoomsPanel = styled(InsetPanel)`
  display: flex;
  align-items: center;  
`;



export const EventsCalendarPage = (props: Props) => {
  const data = useCalendarEvents();

  // const { value: mode, update: setMode } = useBrowserStoredValue("list", "mode", "_raca_calendar_mode");

  useWindowHotkey("alt+n", () => data.newItem.startEditing());

  return (
    <>
      <Form
        title="Календарь"
        fitFullHeight
        formBodyProps={{ style: { flex: "1 0 auto" }}}
        headerItems={<>
            <Tooltip text="Добавить событие (Alt + n)">
              <IconButton color='primary' size="small" onClick={() => data.newItem.startEditing()}><Add /></IconButton>
            </Tooltip>


            <ButtonOptionPicker
              options={[
                [<CalendarMonthOutlined />, "calendar"],
                ["предстоящие", "future"],
                ["прошедшие", "past"],
              ]}
              selected={data.filter.dateFiltering.mode}
              setSelected={s => {
                data.filter.setDateFiltering(
                  s ==="calendar"
                    ? calendarMonthFilterFromDate()
                    : s === "past" ? PastFilter : FutureFilter)
              }}
              />

            <ButtonOptionPicker
              options={[
                ["мои", "my"],
                ["все", "all"],
              ]}
              selected={data.filter.myOnly ? "my" : "all"}
              setSelected={s => data.filter.setMyOnly(s === "my" ? true : false)}
              />
            

            <OccupyFreeSpace />
            {data.isLoading && <LoadingIndicator sizeVariant="m" />}
            <SearchField
              filter={data.filter.filter}
              setFilter={data.filter.setFilter}
              noButton
              autoFocus
              />
            
            
            <PluggableDocumentationButton documentationKey="org_events" />
            
            </>}>

          <ConfRoomsPanel>
            <ConfRoomsActivatorPicker
              available={data.rooms}
              selected={data.filter.confRoomsFilter}
              setSelected={data.filter.setConfRoomsFilter}
              />

            <OccupyFreeSpace />

            <Link to="/org/conf-rooms">
              <Button size="small" color="primary" startIcon={<Settings />}>
                переговорки
              </Button>
            </Link>
          </ConfRoomsPanel>
          
          

          {data.filter.dateFiltering.mode !== "calendar" && 
            <TableForFields
              tailAnchor={data.filter.showPast ? data.past.limitTrigger.pagingAnchor : undefined}
              fields={[
                ["title"],
                ["casenbr"],
                ["conf_room_id"],
                ["start_date", { label: "Дата" }],
                ["start_datetime"],
                ["end_datetime"],
                ["confirmation"],
              ]}
              data={!data.filter.showPast ? data.data : data.past.data}
              schema={data.schema}
              fieldElement={f => {
                switch(f) {
                  case "title":
                    return (r,s,o) => (
                      <PseudoLink2 onClick={() => data.editItem.startEditing({ ...r })}>
                        <OwnershipBadge event={r} myEmployeeId={data.myEmployeeId}>
                          <span>
                            {o}
                          </span>
                        </OwnershipBadge>
                      </PseudoLink2>
                    );
                  case "start_date":
                    return r => <EventStartDate event={r} />;
                  case "start_datetime":
                    return r => <EventStartTime event={r} />;
                  case "end_datetime":
                    return r => <EventEndTime event={r} />;
                  case "conf_room_id":
                    return (r,s,o) => data.haveConflicts[r._id]
                      ? <ConflictsBadge hasConflict>
                        <span>{o}</span>
                      </ConflictsBadge>
                      : <>{o}</>
                }
              }}
              sorting={!data.filter.showPast ?  data.sorting : undefined}
              />}

          {data.filter.dateFiltering.mode === "calendar" &&
            <CalendarEventsView
              events={data.data}
              haveConflicts={data.haveConflicts}
              create={(start) => data.newItem.startEditing(start)}
              edit={(event) => data.editItem.startEditing(event)}
              myEmployeeId={data.myEmployeeId || ""}
              schema={data.schema}
              setDateFiltering={data.filter.setDateFiltering}
              />}
          
      </Form>
      

      <EditCalendarEventDialog
        data={data.newItem}
        schema={data.schema}
        />
      
      <EditCalendarEventDialog
        data={data.editItem}
        schema={data.schema}
        />
    </>
  );
}
