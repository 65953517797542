import { FieldType } from "../../../hooks/useSchema";

export type FilterOp = 'equals' | 'not_equals' | 'oneof' | 'empty' | 'notempty' | '<' | '>' | '<=' | '>=' | "lte_rel_today" | "lte_rel_today_or_null" | "gte_rel_today" | "gte_rel_today_or_null";

export interface Filter {
  id: number | string;
  field?: string;
  op: FilterOp;
  value?: any;
}

export const AvailableOps: { key: FilterOp, label: string }[] = [
  { key: "equals", label: "=" },
  { key: "not_equals", label: "не=" },
  { key: "oneof", label: "одно из" },
  { key: "<", label: "<" },
  { key: "<=", label: "<=" },
  { key: ">", label: ">" },
  { key: ">=", label: ">=" },
  { key: "empty", label: "пусто" },
  { key: "notempty", label: "не пусто" },
  { key: "lte_rel_today_or_null", label: "раньше +Х дней или пусто" },
  { key: "lte_rel_today", label: "раньше +Х дней" },
  { key: "gte_rel_today_or_null", label: "позже +Х дней или пусто" },
  { key: "gte_rel_today", label: "позже +Х дней" },
];

export const opNeedsValue = (op: FilterOp) => op !== "empty" && op !== "notempty";
export const opAvailable = (op: FilterOp, ft: FieldType) => {
  switch(op) {
    case "<":
    case ">":
    case "<=":
    case ">=":
      return ft === FieldType.date
        || ft === FieldType.datetime
        || ft === FieldType.decimal
        || ft === FieldType.number;
    case "lte_rel_today":
    case "lte_rel_today_or_null":
    case "gte_rel_today":
    case "gte_rel_today_or_null":
      return ft === FieldType.date
        || ft === FieldType.datetime;
    default:
      return true;
  }
}

export const isFilterActive = (f: Filter) => f.field
  && f.field.length
  && f.value !== "Invalid date"
  && (f.value !== undefined || !opNeedsValue(f.op))

export type Row = Record<string, any>;
