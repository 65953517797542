import React from 'react';
import { Arbitrator } from '../../../typings/Arbitrators';
import { useSchema } from '../../../toolympus/hooks/useSchema';
import { FormControlsForFields, FieldDefinition, PlaceholderField } from "../../../toolympus/components/schemed";
import { Form, SectionTitle, FormGrid } from '../../../toolympus/components/primitives/Forms';
import { SaveButton } from '../../../toolympus/components/primitives/Buttons';
import CasesList from "../../Cases/presentation/CasesList";
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { useTabsState, TabsHeader, TabPanel } from '../../../toolympus/components/primitives/Tabs';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { DeleteButton } from '../../../toolympus/components/primitives/DeleteButton';
import { Comments } from '../../Comments';
import { ArbitratorData } from '../arbitratorsData';
import { SimpleDocumentList } from '../../../toolympus/components/files';
import { ArbitratorSuggestionsTable } from '../../Presidium/Questions/ArbitratorSuggestionsTable';
import { MultiTextEdit } from '../../../toolympus/components/schemed/MultiTextEdit';
import { useSaveable } from '../../../toolympus/components/primitives/useSaveable';
import { useTrackHistory } from '../../../toolympus/components/HistoryFavorites';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';

interface Props {
    data: ArbitratorData;
};


const ArbitratorForm = (props: Props) => {
    const {
        data: arbitrator,
        isUpdated: isArbitratorUpdated,
        update: updateArbitrator,
        save: saveArbitrator,
        remove: removeArbitrator,
        cases,
        documents,
        isLoading,
        suggestions,
        errors,
    } = props.data;
    const { arbitrator: schema } = useSchema();

    const tabsState = useTabsState([
        ["info", "Информация"],
        ["site", "Сайт"],
        ["cases", "Дела"],
        ["suggestions", "Предложения"],
        ["fees_details", "Гонорар"],
        ["documents", "Документы"],
    ], "info", "tab");

    const controls = (fields: FieldDefinition[], withArea?: boolean) => (
        <FormControlsForFields
            fields={fields}
            data={arbitrator}
            schema={schema}
            onChange={(oldRow,changes) => updateArbitrator({ ...oldRow, ...changes })}
            errors={errors}
            withArea={withArea}
            />
    );

    const isIncludedInERSA = (a: Arbitrator) => !!a.experts_list_site && a.experts_list_site.indexOf("ersa") >= 0;

    useSaveable({ save: saveArbitrator, hasChanges: isArbitratorUpdated });
    useTrackHistory(arbitrator.lastname ? `Арбитр ${arbitrator.lastname} ${arbitrator.firstname}` : undefined);

    return (
        <Form
            title="Арбитр"
            headerItems={<>
                {isArbitratorUpdated && <SaveButton action={saveArbitrator} />}
                {isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />
                <Buttons gap="small">
                  {arbitrator._id && <Comments entity="arbitrator" recordId={arbitrator._id} />}
                  <DeleteButton title="Удалить арбитра?" remove={removeArbitrator} />
                  <PluggableDocumentationButton documentationKey="arbitrators" />
                </Buttons>
                </>}>
            
            <FormGrid columns="1fr 1fr 1fr" forceEvenColumns>
                {controls([
                    ["lastname"],
                    ["firstname"],
                    ["middlename"],
                    ["lastname_en"],
                    ["firstname_en"],
                    ["middlename_en"],
                    ["dont_assign_ext"],
                    ["dont_assign_reason", { visibleIf: (a: Arbitrator) => !!a.dont_assign_ext }],
                    ["birthdate"],
                ])}

                <MultiTextEdit
                    key="email"
                    label={schema.email.label}
                    value={arbitrator.email}
                    onChange={v => updateArbitrator({ email: v })}
                    />

                <MultiTextEdit
                    key="phone"
                    label={schema.phone.label}
                    value={arbitrator.phone}
                    onChange={v => updateArbitrator({ phone: v })}
                    />

                {controls([
                    ["available_site"],
                ])}
            </FormGrid>


            <TabsHeader state={tabsState} scrollable />

            <TabPanel state={tabsState.forTab("info")}>
                <FormGrid columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
                    suppressAreasOnSm
                    alignItems="flex-end"
                    areas={[
                        "gender gender citizenship citizenship citizenship specialization specialization specialization",
                        "title title title_en title_en honours honours honours_en honours_en",
                        Array(8).fill("info").join(' '),
                        Array(8).fill("info_en").join(' '),
                        Array(8).fill("address").join(' '),
                    ]}
                    >
                    {controls([
                        ["gender"],
                        ["citizenship", { autoComplete: true }],
                        ["specialization", { autoComplete: true }],
                        ["title"],
                        ["title_en"],
                        ["honours"],
                        ["honours_en"],
                        ["info", { autoRows: true }],
                        ["info_en", { autoRows: true }],
                        ["address", { autoRows: true }],
                    ], true)}
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabsState.forTab("site")}>
                <FormGrid columns="1fr 1fr 1fr" forceEvenColumns style={{ alignItems: "end" }}>
                    {controls([
                        ["country", { autoComplete: true }],
                        ["city"],
                        ["city_en"],

                        ["experts_list_site", { autoComplete: true }],
                        ["specialization_site", { autoComplete: true }],
                        ["area_site", { autoComplete: true }],
                    ])}
                    <Buttons>
                        {controls([
                            ["ersa_degree", { visibleIf: isIncludedInERSA } ],
                            ["ersa_experience", { visibleIf: isIncludedInERSA } ],
                        ])}
                    </Buttons>
                    <div />
                    {controls([
                        ["language", { autoComplete: true }],
                    ])}
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabsState.forTab("cases")}>
                <CasesList cases={cases} />
            </TabPanel>

            <TabPanel state={tabsState.forTab("suggestions")}>
                <ArbitratorSuggestionsTable data={suggestions} hideArbFields />
            </TabPanel>

            <TabPanel state={tabsState.forTab("fees_details")}>
              <FormGrid columns="1fr 1fr" forceEvenColumns>
                <FormGrid columns="1fr" noMargin>
                  <SectionTitle>Паспорт</SectionTitle>

                  {controls([
                    ["passport_series"],
                    ["passport_nbr"],
                    ["passport_issued_at"],
                    ["passport_issued_by"],
                    ["passport_issued_kp"],
                  ])}
                </FormGrid>

                <FormGrid columns="1fr" noMargin>
                  <SectionTitle>Реквизиты</SectionTitle>
                    {controls([
                      ["inn"],
                      ["bank_recipient"],
                      ["bank_bank"],
                      [PlaceholderField],
                      ["bank_account"],
                      ["bank_bic"],
                    ])}
                </FormGrid>

              </FormGrid>

            </TabPanel>

            <TabPanel state={tabsState.forTab("documents")}>
                <SimpleDocumentList data={documents} />
            </TabPanel>
            
        </Form>);
};

export default ArbitratorForm;