import styled from '@emotion/styled';
import { LiveHelpOutlined, GroupOutlined, GavelOutlined, RssFeed } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    current?: string;
}

const MenuLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;

    & > svg:first-child {
        margin-right: 0.25rem;
        transform: scale(0.6);
    }
`;

const MenuItemX = styled.li<{ selected?: boolean }>`
    color: ${props => props.selected ? props.theme.palette.text.primary : props.theme.palette.action.active};

    & > ${MenuLink} > span {
        border-bottom: ${props => props.selected ? 1 : 0}px dotted ${props => props.theme.palette.primary.main};

        max-width: 0;
        overflow: hidden;
        transition: all 0.5s ease;
        display: block;
    }
    & > ${MenuLink}:hover > span {
        max-width: 100px;
    }
`;

const MenuWrapper = styled.ul`
    list-style: none;
    position: absolute;
    top: 1.5em;
    left: -4px;
    padding: 0;

    display: flex;
    gap: 1rem;

    font-size: 0.8rem;
`;

const MenuWrapperOut = styled.div`
    position: relative;
`;

export const PresidiumDropMenu = (props: Props) => {
    return (
        <MenuWrapperOut>
            <MenuWrapper>
                {([
                    ["questions", "Вопросы", LiveHelpOutlined],
                    ["votes", "Голосования", GavelOutlined],
                    ["members", "Президиум", GroupOutlined],
                    ["events", "Лента событий", RssFeed],
                    ] as [string, string, typeof LiveHelpOutlined][])
                    .map(([k, label, Icon]) => (
                        <MenuItemX
                            key={k}
                            selected={k === props.current}>
                            <MenuLink to={`/presidium/${k}`}>
                                <Icon color="inherit" /> <span>{label}</span>
                            </MenuLink>
                        </MenuItemX>
                    ))}
            </MenuWrapper>
        </MenuWrapperOut>
    )
}
