import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, Button, Typography } from '@mui/material';
import { Buttons, OccupyFreeSpace } from '../../../toolympus/components/primitives/ActionRow';
import { FieldDefinition, FieldSettingsPopupButton, FieldsSettings, FormControl, TableForFields, TableRowButtons, useFields } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { ArbitratorSelection, ArbitratorSelectionData } from './usePresidiumQuestionArbitratorsSelection';
import { Tooltip } from '../../../toolympus/components/primitives/Tooltip';
import { Add, CasinoOutlined, Clear, EditOutlined } from '@mui/icons-material';
import { FieldSorting } from '../../../toolympus/hooks/useFieldSorting';
import { FieldFiltersPanel, FiltersButton } from '../../../toolympus/components/schemed/Filtering';
import { LoadingIndicator } from '../../../toolympus/components/primitives/LoadingIndicator';
import { useVisibilityTrigger } from '../../../toolympus/components/primitives/useVisibilityTrigger';
import { useFullscreen } from '../../../toolympus/components/primitives/useFullscreen';
import { SelectButton } from '../../../toolympus/components/primitives/SelectButton';
import { PseudoLink } from '../../../toolympus/components/primitives/Common.styles';
import { FormattedMessage } from 'react-intl';
import { downloadFile } from '../../../toolympus/api/core';
import { SearchField } from '../../../toolympus/components/primitives/SearchField';
import { EditSavedFiltersDialog, FiltersToSaveDefaults, SavedFiltersSelection, SaveFiltersDialog, useSavedFilters } from '../../../toolympus/components/schemed/Filtering/useSavedFilters';
import { InsetPanel } from '../../Common/UI';
import { EmptyState } from '../../../toolympus/components/primitives';

interface SelectButtonProps {
    arbitrator: ArbitratorSelection;
    select: (arbitratorId: string) => void;
    deselect: (arbitratorId: string) => void;
}

const FiltersPanel = styled(InsetPanel)`
    margin-top: 0.5rem;
`;

export const SelectButtonX = ({ arbitrator, select, deselect }: SelectButtonProps) => {
    return <SelectButton
                isSelected={!!arbitrator.question_id}
                select={() => select(arbitrator.arbitrator_id)}
                deselect={() => deselect(arbitrator.arbitrator_id)}
                selectTooltip="Выбрать"
                deselectTooltip="Убрать"
                />

}


interface Props {
    data: ArbitratorSelectionData;
    caseId: string | null | undefined;
}

const defaultFields = [
    "lastname",
    "firstname",
    "middlename",
    "country",
    "city",
    "experts_list_site",
    "info",
    "phone",
    "email",
];

interface TableProps {
    data: ArbitratorSelection[];
    fields?: FieldDefinition[];
    schema: Schema;
    select: (arbitratorId: string) => void;
    deselect: (arbitratorId: string) => void;

    openDetails?: (r: ArbitratorSelection) => void;
    updateArbitrator?: (arbitratorId: string, changes: Partial<ArbitratorSelection>) => void;

    sorting?: FieldSorting;
    disabled?: boolean;
    allowExclude?: boolean;
}

export const ArbitratorsTable = ({ data, schema, fields, select, deselect, sorting, openDetails, updateArbitrator, allowExclude }: TableProps) => {
    const fs = [
        ["question_id", { width: 50 }],
        ...(fields || defaultFields.map(f => ([f])))
    ] as FieldDefinition[];

    return (
        <TableForFields
            data={data}
            schema={schema}
            fields={fs}
            fieldLink={f =>  (f === 'firstname' || f === 'lastname' || f === 'middlename' ? ({_id}: { _id: string}) => `/arbitrator/${_id}` : null)}
            fieldElement={f => {
                switch(f) {
                    case "question_id":
                        return row => <SelectButtonX arbitrator={row} select={select} deselect={deselect} />;
                    case "document_id": 
                        return row => row.document_id
                            ? <PseudoLink
                                key="download"
                                onClick={() => downloadFile(`/api/presidium/question/${row.question_id}/documents/${row.document_id}`)}>
                                <FormattedMessage id="common.download" />
                              </PseudoLink>
                            : openDetails ? <PseudoLink key="details" onClick={() => openDetails(row)}><FormattedMessage id="common.upload" /></PseudoLink> : <></>;
                }
            }}
            sorting={sorting}
            rowButtons={(openDetails || allowExclude)
                ? row => (
                    <TableRowButtons>
                        {openDetails && <IconButton size="small" onClick={() => openDetails(row)}><EditOutlined /></IconButton>}
                        {allowExclude && 
                            <Tooltip text={row.is_excluded ? "отменить исключение" : ""}>
                                <Button
                                    color="secondary"
                                    variant={row.is_excluded ? "contained" : undefined}
                                    size="small"
                                    onClick={() => updateArbitrator && updateArbitrator(row._id, { is_excluded: !row.is_excluded })}
                                    >
                                    {row.is_excluded ? "исключен" : "исключить"}
                                </Button>
                            </Tooltip>}
                    </TableRowButtons>
                    )
                : undefined}
            onDblClick={openDetails ? row => openDetails(row) : undefined}
            onChange={updateArbitrator ? (o,c) => updateArbitrator(o.arbitrator_id, c) : undefined}
            />
    )
}

export const useArbitratorSelectionFields = (storageKey: string, schema: Schema): FieldsSettings => {
    return useFields({
        defaultFields: [
            "lastname",
            "firstname",
            "middlename",
            "country",
            "city",
            "experts_list_site",
            "info",
            "phone",
            "email",
        ],
        schema,
        storageKey,
    });
}

export const QuestionArbitratorsSelection = (props: Props) => {
    const { 
        isLoading,
        data,
        limitTrigger,
        filter, 
        setFilter, 
        ensureLoaded, 
        includeDontAssign, 
        setIncludeDontAssign,

        filtering,
        setFiltersFromCase,
        isSettingFilters,

        schema,
    } = props.data;

    const fields = useArbitratorSelectionFields("fields_pres_q_arbitrators_selection", schema);

    // const autoexpander = useListAutoexpander(data, 20, 20);
    const loadTrigger = useVisibilityTrigger({
        onBecameVisible: ensureLoaded,
    });

    const fullscreen = useFullscreen();

    const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

    const savedFilters = useSavedFilters(filtering.userSettingsKey || "");

    useEffect(() => {
      if(isFiltersOpen) {
        savedFilters.ensureLoaded();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFiltersOpen]);

    const content = (
        <>
            <Buttons>
                <FiltersButton fieldFilters={filtering} isOpen={isFiltersOpen} setIsOpen={setIsFiltersOpen} />
                {isFiltersOpen &&
                    <Tooltip text_id="fieldsfilters.add">
                        <IconButton size="small" color="primary" onClick={filtering.add}>
                            <Add />
                        </IconButton>
                    </Tooltip>}
                {isFiltersOpen &&
                    <Tooltip text_id="fieldssettings.reset">
                        <IconButton size="small" onClick={filtering.clear}>
                            <Clear />
                        </IconButton>
                    </Tooltip>}
                {isFiltersOpen &&
                    <Button
                        color="primary"
                        size="small"
                        disabled={!props.caseId || isSettingFilters}
                        onClick={() => setFiltersFromCase(props.caseId)}>
                    фильтры по делу {isSettingFilters && <LoadingIndicator sizeVariant="s" />} 
                </Button>}

                {isFiltersOpen && !!filtering.filters.length &&
                    <Button
                        color="primary"
                        size="small"
                        disabled={!props.caseId || isSettingFilters}
                        onClick={() => savedFilters.saveFilters.startEditing({ ...FiltersToSaveDefaults, filters: filtering.filters })}>
                    сохранить фильтры
                </Button>}

                <Tooltip text="Выбрать случайного с учетом фильтров">
                  <IconButton color="primary" onClick={() => props.data.pickRandom.run()} disabled={props.data.pickRandom.isRunning || props.data.disableEdits}>
                    {props.data.pickRandom.isRunning ? <LoadingIndicator sizeVariant="s" /> : <CasinoOutlined />}
                  </IconButton>
                </Tooltip>

                {isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />

                <FormControl
                    field="v"
                    schema={{ label: 'Включая "Не назначать"', type: FieldType.bool }}
                    row={{ v: includeDontAssign }}
                    onChange={(_, changes) => setIncludeDontAssign(changes.v)}
                    extraProps={{ wrapperStyle: { "alignSelf": "flex-end", "marginRight": "1rem" }, controlProps: { size: "small"}}}
                    />
                <SearchField
                    filter={filter}
                    setFilter={setFilter}
                    noButton
                    />
                <FieldSettingsPopupButton fieldsSettings={fields} />
                {fullscreen.button}
            </Buttons>
            {loadTrigger.anchor}
            {isFiltersOpen && <FiltersPanel>
                {!filtering.filters.length && <EmptyState
                  padding="small"
                  text={<Typography variant="caption"><FormattedMessage id="fieldsfilters.add" /></Typography>}
                  onClick={filtering.add}
                  />}
                <FieldFiltersPanel fieldFilters={filtering} hideButtons noSavedFilters />

                {savedFilters.isAvailable &&
                  <SavedFiltersSelection
                    data={savedFilters}
                    selectFilter={f => filtering.set(f.filters, true)}
                    appendFilter={f => filtering.set([ ...filtering.filters, ...f.filters ], true)}
                    />}
                {savedFilters.isAvailable && <SaveFiltersDialog data={savedFilters.saveFilters} isGlobalAvailable={savedFilters.canUpdateGlobal} />}
                {savedFilters.isAvailable && <EditSavedFiltersDialog data={savedFilters.editFilters} remove={savedFilters.removeFilters.run} />}
            </FiltersPanel>}

            <ArbitratorsTable
                {...props.data}
                fields={fields.activeFields}
                data={data}
                />
            {limitTrigger.pagingAnchor}
        </>);

    return fullscreen.renderContent(content);
}
