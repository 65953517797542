import React from 'react';
import { EditItemProps } from '../../../toolympus/api/useNewItem';
import { CalendarEventsSettings } from './useCalendarEventsSettings';
import { FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';

interface Props {
  data: EditItemProps<CalendarEventsSettings>;
  schema: Schema;
}

export const CalendarEventsSettingsDialog = (props: Props) => {
  const { data, schema } = props;

  return (
    <SimpleDialog
      dialogTitle="Настройки календаря"
      submitOnModEnter
      noFullscreen
      isOpen={data.isEditing}
      close={() => data.cancel()}
      save={() => data.save()}>
        <FormGrid columns="1fr">
          {!!data.item &&
            <FormControlsForFields
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={schema}
              fields={[
                ["raca_admin_staff_for_calendar", { autoComplete: true }],
              ]}
              />}
        </FormGrid>
    </SimpleDialog>
  );
}
