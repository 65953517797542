import { useEffect, useState } from 'react';
import { Case } from '../../../typings/Cases';
import { usePagedLoadedData } from '../../../toolympus/hooks/useLoadedData';


interface CaseSelectionData {
    isLoading: boolean;
    cases: Case[];
    filter: string | null;
    setFilter: (v: string) => void;
    doSearch: () => void;
}

export const useCaseSelection = ({ doLoad }: { doLoad?: boolean }): CaseSelectionData => {
    const [filter, setFilter] = useState<string>("");
    
    const [params, setParams] = useState<{ view: string, filter: string, "page-size": number}>({
        view: "user-cases-current",
        filter: "",
        "page-size": 100,
    });
    
    useEffect(() => {
      const timeout = setTimeout(() => {
        setParams(x => ({ ...x, filter, view: filter?.length ? "all" : "user-cases-current" }));
      }, 500);

      return () => clearTimeout(timeout);
    }, [filter]);


    const data = usePagedLoadedData<Case>(`/api/case?${Object.entries(params).map(([k,v]) => `${k}=${v}`).join('&')}`, [], doLoad === undefined || !!doLoad);


    return {
        isLoading: data.isLoading,
        cases: data.data,
        filter,
        setFilter,
        doSearch: () => { setParams({ ...params, filter, view: filter && filter.length ? 'all' : "user-cases-current" })},
    };
}