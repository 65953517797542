import { useEffect, useMemo, useState } from 'react';
import { FieldSchema, FieldType, Schema, schemaFieldsSorter } from '../../../hooks/useSchema';
import { createFilterFn } from './filterFunctions';
import { Filter, Row, AvailableOps, opNeedsValue, opAvailable, isFilterActive } from './typings';
import { generateCode } from '../../../api/data';

export interface FieldFilters {
    filters: Filter[];
    filtersParam: { filters: any[] } | undefined;
    isActive: boolean;
    schema: Schema;
    userSettingsKey?: string;
    filterSchemas: (filter: Filter) => {
        field: FieldSchema;
        op: FieldSchema;
        value: FieldSchema;
        needsValue: boolean;
    };

    add: () => void;
    addPopulated: (filter?: Partial<Filter>) => void;
    update: (old: Filter, changes: Partial<Filter>) => void;
    remove: (f: Filter) => void;
    clear: () => void;
    set: (filters: Filter[], newIds?: boolean) => void;

    filterFn: (row: Row) => boolean;
}

interface Settings {
    storageKey: string;
    userSettingsKey?: string;
}

export const FieldFiltersQueryParam = "field_filters";

export const useFieldFilters = (schema: Schema, data: Row[], settings?: Partial<Settings>): FieldFilters => {
    const [filters, setFilters] = useState<Filter[]>([]);
    const [isRestored, setIsRestored] = useState<boolean>(false);

    const storageKey = settings?.storageKey;

    useEffect(() => {
        if(storageKey && isRestored) {
            localStorage.setItem(storageKey, JSON.stringify(filters));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    useEffect(() => {
        if(storageKey) {
            const saved = localStorage.getItem(storageKey);
            setIsRestored(true);
            if(saved) {
                try {
                    const filters = JSON.parse(saved);
                    setFilters(filters as Filter[]);
                } catch(e) {    
                }
            }
        }
    }, [storageKey]);

    const add = () => {
        addPopulated({});
    };

    const addPopulated = (filter?: Partial<Filter>) => {
        setFilters(fs => {
            let newId = generateCode();
            return [...fs, { id: newId, op: 'equals', ...filter }];
        });
    };

    const update = (old: Filter, changes: Partial<Filter>) => {
        const targetField = changes.field || old.field;
        const targetSchema = schema[targetField || ""];
        if(changes.field && changes.field !== old.field) {
            changes.value = undefined;
            if(!opAvailable(old.op, targetSchema.type || FieldType.text)) {
                changes.op = 'equals';
            }
        }

        const targetValue = changes.value || old.value;
        if(changes.op === "oneof" && !Array.isArray(targetValue)) {
            changes.value = [];
        } else if(changes.op
            && old.op === "oneof"
            && changes.op !== "oneof"
            && targetSchema
            && targetSchema.type !== FieldType.multiselect 
            && targetSchema.type !== FieldType.dictionarySelectMulti
            && Array.isArray(targetValue)) {
            changes.value = targetValue.length ? targetValue[0] : undefined;
        } else if(changes.op && !opNeedsValue(changes.op)) {
            changes.value = undefined;
        }

        const copy = [...filters];
        const idx = copy.findIndex(f => f.id === old.id);
        if(idx >= 0) {
            copy.splice(idx, 1, { ...old, ...changes });
        }
        setFilters(copy);
    }

    const remove = (f: Filter) => {
        setFilters(filters.filter(ff => ff.id !== f.id));
    }

    const fields = Object.keys(schema)
        .sort(schemaFieldsSorter(schema))
        .map(key => {
            const fieldSchema = schema[key];
            const label = fieldSchema && fieldSchema.label ? `${fieldSchema.label} (${key})` : key;
            return { key, label }
        });

    const fieldFieldSchema: FieldSchema = {
        label: "Поле",
        type: FieldType.select,
        values: fields.map(({ key, label }) => ({ value: key, label })),
        valueDict: fields.reduce((r, { key, label }) => ({ ...r, [key]: label }), {}),
    }

    const filterSchemas = (filter: Filter) => {
        const valueFieldSchema = { ...schema[filter.field || ""], label: " ", label_id: undefined, hint: undefined, hint_id: undefined };

        const ops = AvailableOps.filter(op => opAvailable(op.key, valueFieldSchema.type || FieldType.text));

        const opFieldSchema: FieldSchema = {
            label: "Оператор",
            type: FieldType.select,
            values: ops.map(({ key, label }) => ({ value: key, label })),
            valueDict: ops.reduce((r, { key, label }) => ({ ...r, [key]: label }), {}),
        }

        
        if(filter.op === "oneof") {
            switch(valueFieldSchema.type) {
                case FieldType.dictionarySelectMulti:
                case FieldType.multiselect:
                    break;    
                case FieldType.select:
                    valueFieldSchema.type = FieldType.dictionarySelect;
                    break;
                case FieldType.dictionarySelect:
                    valueFieldSchema.type = FieldType.dictionarySelectMulti;
                    break;
                default:
                    valueFieldSchema.type = FieldType.multiselect;
                    const values = [...new Set(data.map(r => r[filter.field || ""]).filter(v => v !== null && v !== undefined))].sort();
                    valueFieldSchema.values = values.map(value => ({ value, label: value.toString() }));
                    valueFieldSchema.valueDict = values.reduce((r,v) => ({ ...r, [v as unknown as string | number]: v}), {});
                    break;
            }
        }

        if(filter.op === "lte_rel_today" || filter.op === "lte_rel_today_or_null" || filter.op === "gte_rel_today" || filter.op === "gte_rel_today_or_null") {
          valueFieldSchema.type = FieldType.number;
        }
        
        return {
            field: fieldFieldSchema,
            op: opFieldSchema,
            value: valueFieldSchema,
            needsValue: opNeedsValue(filter.op),
        }
    }

    const activeFilters = filters.filter(isFilterActive);
    const filterFns = activeFilters.map(f => createFilterFn(schema, f));

    const filterFn = (row: Record<string, any>) => filterFns.every(fn => fn(row));

    const filtersParam = useMemo(() => {
      const filtersA = filters.filter(isFilterActive);
      return filtersA.length
        ? {
            filters: filtersA.map(f => ({
              f: f.field,
              op: f.op,
              v: f.value,
              ft: schema[f.field || ""]?.type,
            }))
          }
        : undefined;
    }, [filters, schema]);

    return {
        filters,
        filtersParam,
        isActive: activeFilters.length > 0,
        schema,
        filterSchemas,
        userSettingsKey: settings?.userSettingsKey,

        add,
        addPopulated,
        update,
        remove,
        set: (f, newIds) => {
          const copy = newIds
            ? f.map(fx => ({ ...fx, id: generateCode() }))
            : f;
          setFilters(copy);
        },
        clear: () => setFilters([]),

        filterFn,
    };
}